html {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.block-window-scroll {
  height: 100%;
  overflow: hidden;
}
